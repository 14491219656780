import React from "react";

// Components
import Link from "./Link";

interface MenuButtonProps {
  link: string;
  label: string;
  icon: string;
  visible: boolean;
  delay: number;
  isCurrent: boolean;
  subitem: boolean;
  closeMenu: () => void;
}

class MenuButton extends React.Component<MenuButtonProps> {
  render() {
    return (
      <Link
        key={this.props.label}
        to={this.props.link}
        className={`flex-none transition-all ${
          this.props.visible ? "opacity-100" : "-translate-x-full opacity-0"
        }`}
        style={{
          transitionDelay: `${this.props.delay}ms`,
        }}
        onClick={this.props.closeMenu}
      >
        <p
          className={`
            transition-all origin-center w-full flex items-center  z-20 text-opacity-80 active:opacity-30
            ${
              this.props.subitem
                ? "text-base gap-4 rounded-md ml-4 py-2 px-4 hover:opacity-80"
                : "text-xl gap-6 rounded-r-md lg:pl-16 pl-8 pr-8 py-2"
            } font-bold select-none bg-white hover:bg-opacity-100 text-primary
            hover:translate-x-0
            ${
              this.props.isCurrent
                ? "opacity-70 pointer-events-none translate-x-0"
                : `${
                    this.props.subitem ? "" : "-translate-x-4 lg:-translate-x-8"
                  }`
            }
          `}
        >
          <span
            className={`material-symbols-rounded ${
              this.props.subitem ? "text-2xl" : "text-4xl"
            } text-left`}
          >
            {this.props.icon}
          </span>
          {this.props.label}
        </p>
      </Link>
    );
  }
}

type TopMenuProps = {
  currentYear: number;
  seminar: string;
  mootcourt: string;
  color: string;
};

export default class TopMenu extends React.Component<TopMenuProps> {
  state = {
    menuOpen: false,
    currentUrl: "",
  };

  setMenuOpen = (open: boolean) => {
    this.setState({ menuOpen: open });
  };

  useEffect() {
    this.setState({
      currentUrl: window.location.pathname,
    });
  }

  render() {
    const menu = [
      {
        title: "Overzicht",
        link: "/",
        icon: "home",
      },
      {
        title: `Justitia ${this.props.currentYear ?? ""}`.trim(),
        link: `/${this.props.currentYear}`,
        icon: "event",
        subitems: [
          {
            title: "Programma",
            link: `/${this.props.currentYear}`,
            icon: "event_note",
          },
          this.props.mootcourt
            ? {
                title: `Pleitwedstrijden`,
                link: this.props.mootcourt,
                icon: "gavel",
              }
            : null,
          this.props.seminar
            ? {
                title: `Seminar & Eindfeest`,
                link: this.props.seminar,
                icon: "event_available",
              }
            : null,
          {
            title: `Bestuur`,
            link: `/${this.props.currentYear}/bestuur`,
            icon: "groups_2",
          },
          {
            title: `Foto's`,
            link: `/${this.props.currentYear - 1}/fotos`,
            icon: "photo_camera",
          },
        ].filter((item) => item),
      },

      {
        title: "Sponsoren",
        link: "/sponsoren",
        icon: "volunteer_activism",
      },
      {
        title: "Archief",
        link: "/archief",
        icon: "history",
      },
    ];
    return (
      <>
        {/* Background layer */}
        <div
          onClick={() => this.setMenuOpen(false)}
          className={`${
            this.state.menuOpen
              ? "bg-opacity-90 backdrop-blur-lg cursor-pointer"
              : "bg-opacity-0 pointer-events-none"
          } fixed top-0 left-0 w-full h-full z-100 bg-primary transition-all duration-700 select-none`}
        />
        {/* Toggle Button */}
        <div
          onClick={() => {
            this.setMenuOpen(this.state.menuOpen ? false : true);
          }}
          className={`lightbox-hide fixed bg-primary text-white top-0 z-400 left-0 overflow-hidden box-border flex flex-col transition-all m-4 font-bold  rounded-xl ${
            this.state.menuOpen
              ? "bg-opacity-0 text-white"
              : `bg-opacity-90 backdrop-blur-lg`
          }`}
          style={{
            backgroundColor: this.props.color,
          }}
        >
          <p className="flex items-center m-2 justify-center w-9 h-9 select-none cursor-pointer transition-all hover:scale-125 active:scale-95">
            <span className="material-symbols-rounded text-3xl font-black">
              {this.state.menuOpen ? "close" : "menu"}
            </span>
          </p>
        </div>
        {/* Menu */}
        <div
          className={`fixed top-0 left-0 z-200 flex flex-col items-start select-none pt-32 gap-4 h-full text-white ${
            this.state.menuOpen ? "" : "pointer-events-none"
          }`}
        >
          {menu.map((item, index) => (
            <>
              <MenuButton
                key={index}
                link={item.link}
                isCurrent={this.state.currentUrl === item.link}
                label={item.title}
                icon={item.icon}
                visible={this.state.menuOpen}
                delay={100 * index}
                subitem={false}
                closeMenu={() => {
                  this.setMenuOpen(false);
                }}
              />
              {item.subitems &&
                item.subitems
                  .filter((item) => item)
                  .map((subitem, subindex) => (
                    <MenuButton
                      key={`${index}+${subindex}`}
                      link={subitem!.link}
                      isCurrent={this.state.currentUrl === subitem!.link}
                      label={subitem!.title}
                      icon={subitem!.icon}
                      visible={this.state.menuOpen}
                      delay={100 * index + 100 * subindex}
                      subitem={true}
                      closeMenu={() => {
                        this.setMenuOpen(false);
                      }}
                    />
                  ))}
            </>
          ))}
        </div>
      </>
    );
  }
}
